import React from 'react';
import PropTypes from 'prop-types';
import './CartSummary.scss';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import { Checkbox } from 'antd';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { printStandardDecimals } from 'Utils/cartUtils';
import FormItemWrapper from 'Components/Form/FormItemWrapper/FormItemWrapper';
import { isHolidayToday, holidays } from 'Utils/holidayUtils';
import HolidayContent from 'Components/HolidayContent/HolidayContent';
import OperatingTimeContent from 'Components/OperatingTimeContent/OperatingTimeContent';
import SummaryItemGroupContainer from './SummaryItemGroupContainer';
import moment from 'moment';

const CartSummaryTPL = (props) => {
  const { cart,
          cartSummary,
          handleSwitchToCart,
          isInCheckout,
          handleSubmitOrder,
          canSubmitOrder
       } = props;

  const subtotal = !isNil(cartSummary) && 'subtotal' in cartSummary ? cartSummary.subtotal : undefined;
  const deposits = !isNil(cartSummary) &&  'deposits' in cartSummary ? cartSummary.deposits : undefined;
  const itemsInCart = !isNil(cartSummary) ? cartSummary['items_in_cart'] : [];
  const itemsInCartCost = itemsInCart.reduce((previousValue, nextValue) =>
                            previousValue + nextValue.amount, 0);
  const deliveryFee = cart.delivery_fee;
  const depositsCost = !isNil(deposits) ? deposits.reduce((previousValue, nextValue) => {
    return previousValue + nextValue.amount;
  }, 0) : 0;

  /* checker for selected holiday when the disabled checkout should show */
  const currDate = moment(new Date()).format('YYYY-MM-DD'); 
  // const currDate = '2024-03-27 22:05'; // static current date
  
  console.log('currDate', currDate);
  
  const disabledCheckoutStart = moment('2024-03-27 22:00');
  const disabledCheckoutEnd = moment('2024-03-30 09:00'); 

  const disabledCheckoutOnThisDays = [
    '2023-10-29 00:00',
    '2023-10-30 23:59'
  ];

  // const isDisabledCheckout = disabledCheckoutOnThisDays.includes(currDate);
  const isDisabledCheckout = moment(currDate).isBetween(disabledCheckoutStart, disabledCheckoutEnd); // use if holiday is ranged
  // const isDisabledCheckout = currDate === '2023-12-25' || currDate === '2024-01-01'; // use if disabled date are not consecutive

  console.log('IS HOLIDAY?', isDisabledCheckout)
  
    return (
      <div className="cart-summary-col">
        <div className="cart-summary -cart-summary-col">
          <h1 className="heading">Order Summary</h1>
          <div className="summary-items-container">
            <div className="summary-items-group">
              <div className="summary-items-heading">
                <span className="summary-heading-label">Items in Cart</span>
                <span className="summary-items-total">P {printStandardDecimals(itemsInCartCost)}</span>
              </div>

              {
                itemsInCart.length > 0 &&

                <ul className="summary-items-list">

                {
                  itemsInCart.map((itemInCart, index) => {
                    return (
                      <li className="summary-item" key={index}>
                        <span className="item-name">{itemInCart.name}</span>
                        <span className="item-cost">P {printStandardDecimals(itemInCart.amount)}</span>
                      </li>
                    )
                  })
                }
              </ul>
              }
            </div>

            {!isNil(deposits) && <SummaryItemGroupContainer>
              <div className="summary-items-heading">
                <span className="summary-heading-label">Deposits</span>
                <span className="summary-items-total">P {printStandardDecimals(depositsCost)}</span>
              </div>
              <ul className="summary-items-list">

                {
                  Array.isArray(deposits) && deposits.map((deposit, index) => {
                    return (
                      <li className="summary-item" key={index}>
                        <span className="item-name">{deposit.name}</span>
                        <span className="item-cost">P {printStandardDecimals(deposit.amount)}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </SummaryItemGroupContainer>}

            <SummaryItemGroupContainer>
            <div className="summary-items-heading">
                <span className="summary-heading-label">Delivery Fee</span>
                <span className="summary-items-total">P {printStandardDecimals(deliveryFee)}</span>
            </div>
            </SummaryItemGroupContainer>
            {/* <div className="summary-items-group">
              <div className="summary-items-heading">
                <span className="summary-heading-label">Items in Cart</span>
              </div>
              <ul className="summary-items-list">
                <li className="summary-item">
                  <span className="item-name">3 Cases</span>
                  <span className="item-cost">P 1,543.00</span>
                </li>
              </ul>
            </div> */}

            {/* <div className="cart-voucher-form">
                <div className="cart-discount-field">
                  <input type="text" className="discount-text-input" placeholder="VOUCHER" />
                  <button className="discount-apply-trigger">Apply</button>
                </div>
                <div className="cart-discount-error-container">
                  <p className="message">Invalid Code</p>
                </div>
            </div> */}


            <section className="cart-subtotal-container -cart-summary">
              {!isNil(subtotal) && <div className="subtotal-row -cart-summary">
                <span className="label-text -cart-summary">Subtotal</span>
                <span className="subtotal-figure -cart-summary">P {printStandardDecimals(subtotal)}</span>
              </div>}


              {
                !isInCheckout ?
                <>
                  <div className="review-trigger-container">

                  {/* <HolidayContent
                    timeBoundHoliday
                    useLocaltime>
                    <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block disabled>Proceed To Checkout</GradientBtn>
                  </HolidayContent>

                  <HolidayContent
                    timeBoundHoliday
                    useLocaltime
                    showWhenNotHoliday>
                    <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block>Proceed To Checkout</GradientBtn>
                  </HolidayContent> */}

                    {/* <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block>Proceed To Checkout</GradientBtn> */}
                    {/* <Link to="browse" className="ant-btn continue-trigger -transparent -block">Continue Shopping</Link> */}

                    {/* CHRISTMAS BREAK */}

                    { isDisabledCheckout ? (
                      <OperatingTimeContent showWhenNotOperating>
                        <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block disabled>Proceed To Checkout</GradientBtn>
                      </OperatingTimeContent>
                    ) : (
                      <OperatingTimeContent>
                        <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block >Proceed To Checkout</GradientBtn>
                      </OperatingTimeContent>
                    )}

        
                    {/* <OperatingTimeContent showWhenNotOperating>
                      <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block disabled>Proceed To Checkout</GradientBtn>
                    </OperatingTimeContent> */}


                  </div>
                  <div className="tax-info-container">
                    {/* <p className="tax-info">*Tax &amp; deposits will be calculated at checkout</p> */}
                    <p className="tax-info">*Deposits will be calculated at checkout</p>
                  </div>
                </>
                :
                <>
                <div className="review-trigger-container">

                  {/* <FormItemWrapper name="policy_agreement_accepted">
                    <Checkbox className="terms-checkbox -display-block" onChange={handleToggleTC}  checked={didAgreeToTC} name={'policy_agreement_accepted'}>
                      I agree to the <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link> and <Link to="privacy-policy" target="_blank">Privacy Policy</Link>.
                    </Checkbox>
                  </FormItemWrapper> */}


                  <GradientBtn
                    disabled={!canSubmitOrder}
                    className="review-trigger"
                    onClick={handleSubmitOrder}
                    type="submit"
                    block>Submit Order</GradientBtn>
                </div>
                <div className="tax-info-container">
                    
                    <p className="tax-info">*Tax &amp; deposits will be calculated at checkout</p>
                </div>
                </>
              }
            </section>


          </div>
        </div>
      </div>)
};

export default CartSummaryTPL;


CartSummaryTPL.propTypes = {
  cartSummary: PropTypes.object,
  handleSwitchToCart: PropTypes.func,
  handleSubmitOrder: PropTypes.func,
  isInCheckout: PropTypes.bool,
  didAgreeToTC: PropTypes.bool,
  didAgreeToPolicy: PropTypes.bool,
  handleToggleTC: PropTypes.func
};
